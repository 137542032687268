import { strongPasswordSchema } from 'src/screens/ResetPassword/ResetPassword.schema'
import * as yup from 'yup'

export const PasswordChangeSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: strongPasswordSchema().required('New password is required'),
  confirmNewPassword: strongPasswordSchema()
    .oneOf([yup.ref('newPassword'), null], 'New passwords must match')
    .required('Confirm new password is required'),
})
