import { Formik, Form, FormikHelpers } from 'formik'
import { Button, InputField, Card } from 'src/components'
import { BE_PATHS } from 'src/constants'
import { useAxios, useToast } from 'src/hooks'

import { PasswordChangeSchema } from './PasswordChange.schema'

interface FormValues {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export const PasswordChange = () => {
  const toast = useToast()
  const [{ fetching: changingPassword }, changePassword] = useAxios({ method: 'PUT', url: BE_PATHS.CHANGE_PASSWORD() })

  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    const { currentPassword, newPassword } = values
    try {
      await changePassword({ body: { currentPassword, newPassword } })
      toast('Password changed successfully', { variant: 'success' })
      resetForm()
    } catch (error) {
      toast('Failed to change password', { variant: 'error' })
    }
  }

  return (
    <Card style={{ padding: '1rem' }}>
      <Formik<FormValues>
        initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
        validationSchema={PasswordChangeSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <InputField
              name="currentPassword"
              type="password"
              placeholder="Current password"
              label="Current password"
            />
            <InputField name="newPassword" type="password" placeholder="New Password" label="New password" />
            <InputField
              name="confirmNewPassword"
              type="password"
              placeholder="Confirm New Password"
              label="Confirm new password"
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                disabled={changingPassword}
                loading={changingPassword}
                text="Save"
                variant="accent"
              />
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  )
}
