import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

import { Breadcrumb } from '../Breadcrumb'

export const $Container = styled.divBox`
  width: 100%;
  padding: 0 30px;
  min-height: 70px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  background-color: white;

  ${Styles.screens.small`
		padding: 0 20px;
	`}
`

export const $Bar = styled.divBox`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  z-index: 1;
`

export const $End = styled.divBox`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  color: dark;
  margin-left: 20px;
  overflow-x: auto;

  ${Styles.screens.small`
    div .dashboard__devices-status-card,
    div .btn {
			margin-right: 10px;
    }
	`}
`

export const $Menu = styled.divBox`
  display: none;
  align-items: center;
  cursor: pointer;

  ${Styles.screens.small`
    display: flex;
	`}
`

export const $Breadcrumbs = styled(Breadcrumb)`
  min-height: 35px;
  display: flex;
  align-items: center;
  margin-right: auto;
  color: light;
  font-weight: bold;
`

export const $Title = styled.divBox`
  min-width: 200px;
  font-weight: semibold;
  font-size: 20px;
`
