import React from 'react'

import * as S from './Label.styled'

export interface LabelProps extends SCProps<typeof S.$Label> {
  children: React.ReactNode
  variant?: 'default' | 'success' | 'warning' | 'error'
}

export const Label = ({ children, variant, ...props }: LabelProps) => {
  return (
    <S.$Label data-success={variant === 'success'} data-error={variant === 'error'} {...props}>
      {children}
    </S.$Label>
  )
}
