import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { Modal } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'
import { DeviceSchema } from 'src/screens/Devices/Device/Device.schema'
import DeviceForm from 'src/screens/Devices/Device/DeviceForm'
import { IDevice } from 'src/typings/device'
import { isPayStation } from 'src/utils'

const getFilteredFormData = (data: Partial<IDevice> | undefined) => {
  if (!data) return

  const fieldsToOmit = ['id']

  if (!data.supportsPrinting) fieldsToOmit.push('printerIp', 'printerPrints')

  if (data.type !== 'whitelist') fieldsToOmit.push('whitelistMinutes')

  if (!isPayStation(data.type)) fieldsToOmit.push('alioIp', 'alioPort')

  return _.omit(data, fieldsToOmit)
}

interface Props {
  isOpen: boolean
  device?: Partial<IDevice>
  locationId?: string
  tariffs?: ITariff[]
  onSubmitSuccess: (device: IDevice) => void
  onClose: () => void
}

export const DeviceModal = ({ isOpen, device, locationId, tariffs, onSubmitSuccess, onClose }: Props) => {
  const toast = useToast()
  const { organisation } = useOrganisationContext()
  const [, submitData] = useAxios<Partial<IDevice>>({
    method: device?.name ? 'PUT' : 'POST',
    url: device?.id ? `${BE_PATHS.DEVICES}/${device?.id}` : BE_PATHS.DEVICES,
  })

  const [formData, setFormData] = React.useState<Partial<IDevice>>()

  const initialValues = {
    alioIp: '192.168.1.',
    alioPort: '50051',
    printerIp: '192.168.1.200',
    printerPrints: 1600,
    remotePort: '50040',
    type: 'pay_on_entry',
    supportsPrinting: true,
    locationId,
    organisationId: organisation?.id,
    whitelistMinutes: 0,
    ...device,
  } as Partial<IDevice>

  return (
    <Formik initialValues={initialValues} validationSchema={DeviceSchema} onSubmit={() => {}}>
      {({ errors, validateForm, setTouched }) => {
        return (
          <Modal
            style={{ w: '95%', maxWidth: 750 }}
            title={!device ? 'New device' : 'Edit device'}
            body={
              <DeviceForm
                device={device || initialValues}
                tariffs={tariffs}
                onChange={setFormData}
                isActive={isOpen}
                errors={errors}
              />
            }
            confirmText={!device ? 'Create' : 'Save'}
            variant="accent"
            onConfirm={async () => {
              try {
                const validationErrors = await validateForm(formData)

                setTouched(Object.keys(validationErrors).reduce((acc, key) => ({ ...acc, [key]: true }), {}) as any)

                if (Object.keys(validationErrors).length > 0) {
                  toast(`Form validation has failed, please complete required fields`, { variant: 'error' })
                  return
                }
                const filteredFormData = getFilteredFormData(formData)

                const newDevice = await submitData({ body: filteredFormData })
                newDevice && onSubmitSuccess(newDevice as IDevice)
                toast(`${formData?.name || 'Device'} has been saved`, { variant: 'success' })
                setFormData(undefined)
                onClose()
              } catch (error) {
                toast(`Something went wrong, please try again.`, { variant: 'error' })
              }
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
        )
      }}
    </Formik>
  )
}
