import * as MaterialIcons from '@mui/icons-material'
import DashboardIcon from '@mui/icons-material/Apps'
import OrganisationsIcon from '@mui/icons-material/CorporateFare'
import BarriersIcon from '@mui/icons-material/Fence'
import UsersIcon from '@mui/icons-material/Group'
import LocationsIcon from '@mui/icons-material/LocationOn'
import TransactionsIcon from '@mui/icons-material/Payment'
import PermitIcon from '@mui/icons-material/Payments'
import BarrierEventsIcon from '@mui/icons-material/QueryStats'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TabProps } from 'src/components/Tab'
import { useWindowSize } from 'src/hooks'

import { BreadcrumbProps, Condition, OverlaySpinner, SearchbarProps, Sidebar, Topbar } from '../../components'
import { FE_PATHS } from '../../constants/paths'
import { useAuthContext } from '../../context'

import * as S from './Page.styled'

interface PageProps {
  searchValue?: string
  children: Children
  endSlot?: React.ReactNode
  loading?: boolean
  title?: string
}

export default function Page({
  searchValue,
  onSearch,
  sanitizer,
  searchPlaceholder,
  children,
  endSlot,
  loading = false,
  breadcrumb,
  title,
}: PageProps & SearchbarProps & { breadcrumb?: BreadcrumbProps }) {
  const { isAdmin, isAtLeastManager, user } = useAuthContext()
  const navigate = useNavigate()
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false)

  const redirectTo = (path: string) => () => navigate(path)

  const { width: windowWidth } = useWindowSize()

  React.useEffect(() => {
    if (windowWidth < 770) {
      setIsSidebarOpen(false)
    }
  }, [windowWidth])

  const tabsToRender: TabProps[] = [
    {
      text: 'Dashboard',
      icon: <DashboardIcon fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.DASHBOARD),
      basePath: 'dashboard',
    },
    {
      visible: isAdmin,
      text: 'Organisations',
      icon: <OrganisationsIcon fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.ORGANISATIONS),
      basePath: 'organisations',
    },
    {
      text: 'Locations',
      icon: <LocationsIcon fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.LOCATIONS),
      basePath: 'locations',
    },
    {
      text: 'Barriers',
      icon: <BarriersIcon fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.BARRIERS),
      basePath: 'barriers',
    },
    {
      text: 'Barrier events',
      visible: isAtLeastManager,
      icon: <BarrierEventsIcon fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.BARRIER_EVENTS),
      basePath: 'barrier-events',
    },
    {
      text: 'Permits',
      icon: <PermitIcon fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.PERMITS),
      basePath: 'permits',
    },
    {
      text: 'Bookings',
      icon: <MaterialIcons.BookOnline fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.BOOKINGS),
      basePath: 'bookings',
    },
    {
      text: 'Transactions',
      icon: <TransactionsIcon fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.TRANSACTIONS),
      basePath: 'transactions',
    },
    {
      text: 'Metrics',
      visible: isAtLeastManager,
      icon: <MaterialIcons.TrendingUpOutlined fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.METRICS),
      basePath: 'metrics',
    },
    {
      text: 'EV reports',
      visible: isAtLeastManager,
      icon: <MaterialIcons.ElectricMeter fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.EV_REPORTS),
      basePath: 'ev-reports',
    },
    {
      text: 'EV',
      visible: isAdmin,
      icon: <MaterialIcons.EvStation fontSize="inherit" />,
      //! Please do not change the link below. It is not supposed to have the same domain as the API URL
      onClick: () => window.open('https://cp.twincharge.co.uk/admin/dashboards/main', '_blank'),
      basePath: 'ev',
    },
    {
      text: 'Users',
      visible: isAdmin,
      icon: <UsersIcon fontSize="inherit" />,
      onClick: redirectTo(FE_PATHS.USERS),
      basePath: 'users',
    },
  ]

  return (
    <S.$Container className="PageContainer">
      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} tabs={user ? tabsToRender : []} />
      <S.$Body className="PageBody">
        <Condition when={Boolean(onSearch || endSlot || breadcrumb)}>
          <Topbar
            searchValue={searchValue}
            onSearch={onSearch}
            sanitizer={sanitizer}
            searchPlaceholder={searchPlaceholder}
            onMenuClick={() => setIsSidebarOpen(true)}
            endSlot={endSlot}
            breadcrumb={breadcrumb}
            title={title}
          />
        </Condition>
        <OverlaySpinner loading={loading} />
        {children}
      </S.$Body>
    </S.$Container>
  )
}
