import DotsIcon from '@mui/icons-material/MoreVert'
import { Dropdown } from 'antd'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'
import { FE_PATHS } from 'src/constants'

import { useAuthContext } from '../../context'
import { useClickOutside } from '../../hooks'

import * as S from './ProfileDropdown.styled'

interface ProfileDropdownProps {
  className?: string
  isOpen?: boolean
  onClose: () => void
}

export default function ProfileDropdown({ className = '', isOpen = false, onClose }: ProfileDropdownProps) {
  const visibilityProp = isMobile ? undefined : { visible: isOpen }
  const { user, logout } = useAuthContext()
  const navigate = useNavigate()

  const { ref: dropdownRef, isClickOutside, resetIsClickOutside } = useClickOutside()

  useEffect(() => {
    if (isClickOutside) {
      resetIsClickOutside()
      onClose()
    }
    // eslint-disable-next-line
  }, [isOpen, isClickOutside, resetIsClickOutside])

  return (
    <S.$AntDropdown>
      <Dropdown
        forceRender
        className={`profile-dropdown ${className}`}
        {...visibilityProp}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        placement="topLeft"
        overlay={
          <S.$Menu className="profile-dropdown__menu" ref={dropdownRef}>
            <S.$MenuItem
              className="profile-dropdown__menu__item"
              key="profile-settings"
              onClick={() => {
                navigate(FE_PATHS.SETTINGS)
                onClose()
              }}
            >
              <p>Settings</p>
            </S.$MenuItem>
            <S.$MenuItem
              className="profile-dropdown__menu__item"
              key="profile-logout"
              onClick={() => {
                logout()
                onClose()
              }}
            >
              <p>Logout</p>
            </S.$MenuItem>
          </S.$Menu>
        }
      >
        <S.$Profile tabIndex={0} className="profile-dropdown__profile">
          <S.$Image className="profile-dropdown__image" />
          <S.$User className="profile-dropdown__user">
            <S.$UserName className="profile-dropdown__user__name">{user?.name}</S.$UserName>
            <S.$UserEmail className="profile-dropdown__user__email">{user?.email}</S.$UserEmail>
          </S.$User>
          <S.$Icon>
            <DotsIcon className="profile-dropdown__icon" />
          </S.$Icon>
        </S.$Profile>
      </Dropdown>
    </S.$AntDropdown>
  )
}
