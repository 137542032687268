import * as yup from 'yup'

export const strongPasswordSchema = () => {
  return yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/^(?=.*[a-z])/, 'Password must contain one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Password must contain one uppercase character')
    .matches(/^(?=.*\d)/, 'Password must contain one number character')
    .matches(/^(?=.*[!@#$%^&*])/, 'Password must contain one special case character')
}

export const validationSchema = yup.object({
  newPassword: strongPasswordSchema().required('New password is required'),
  confirmNewPassword: strongPasswordSchema()
    .required('Confirm new password is required')
    .oneOf([yup.ref('newPassword')], 'Passwords do not match'),
})
