import React from 'react'

import * as S from './InputText.styled'

interface InputTextProps {
  placeholder?: string
  icon?: JSX.Element
  disabled?: boolean
  type?: string
  name?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  state?: 'default' | 'success' | 'error'
}

export const InputText = ({
  name,
  value,
  type,
  disabled,
  onChange,
  onBlur,
  autoFocus,
  placeholder,
  inputProps,
  icon,
  state,
}: InputTextProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const element = event.target
    const caret = element.selectionStart

    if (element.type === 'text' || element.type === 'password') {
      window.requestAnimationFrame(() => {
        element.selectionStart = caret
        element.selectionEnd = caret
      })
    }

    onChange?.(event)
  }

  return (
    <S.$Field data-error={state === 'error'} data-success={state === 'success'} data-disabled={disabled}>
      <S.$Value>
        <S.$Input
          name={name}
          type={type}
          value={value}
          disabled={disabled}
          onChange={handleOnChange}
          onBlur={onBlur}
          autoComplete={type === 'password' ? 'off' : 'on'}
          autoFocus={autoFocus}
          placeholder={placeholder}
          {...inputProps}
        />
        <S.$Icon>{icon && icon}</S.$Icon>
      </S.$Value>
    </S.$Field>
  )
}
