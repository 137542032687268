import { Tabs } from '@mantine/core'
import React from 'react'
import { Page, Shell } from 'src/containers'
import * as TablerIcons from 'tabler-icons-react'

import { PasswordChange } from './components'

export const Settings = () => {
  const [activeTab, setActiveTab] = React.useState(0)

  return (
    <Page endSlot title="User Settings">
      <Shell style={{ width: '50%' }}>
        <Tabs active={activeTab} onTabChange={(index) => setActiveTab(index)}>
          <Tabs.Tab color="teal" label="Password" icon={<TablerIcons.Spy size={14} />}>
            <PasswordChange />
          </Tabs.Tab>
        </Tabs>
      </Shell>
    </Page>
  )
}
