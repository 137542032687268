import { DeviceAction } from 'src/typings/device'

export const FE_PATHS = {
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/password-reset',
  LOGIN: '/login',
  BOOKING: (bookingId: string) => `/bookings/${bookingId}`,
  BOOKINGS: '/bookings',
  BARRIERS: '/barriers',
  BARRIER_EVENTS: '/barrier-events',
  DASHBOARD: '/dashboard',
  DEVICES: '/devices',
  DEVICE_ADD: '/devices/new',
  DEVICE_EDIT: '/devices/edit',
  LOCATIONS: '/locations',
  LOCATION: (locationId: string) => `/locations/${locationId}`,
  LOCATION_ADD: '/locations/add',
  LOCATION_EDIT: '/locations/edit',
  ORGANISATIONS: '/organisations',
  ORGANISATION_ADD: '/organisations/add',
  ORGANISATION_EDIT: '/organisations/edit',
  ORGANISATION_VIEW: '/organisations/view',
  TARIFFS: '/tariffs',
  TARIFF_ADD: '/tariffs/new',
  TARIFF_EDIT: '/tariffs/edit',
  TARIFF_LINK: '/tariffs/link',
  TRANSACTIONS: '/transactions',
  PERMITS: '/permits',
  PERMITS_IMPORT: '/permits/import',
  METRICS: '/metrics',
  USERS: '/users',
  USER_ADD: '/users/new',
  USER_EDIT: '/users/edit',
  EV_REPORTS: '/ev-reports',
  SETTINGS: '/settings',
}

const EV_BASE_URL = 'https://twin.uk.charge.ampeco.tech'

export const AMPECO_PATHS = {
  LOGIN: `${EV_BASE_URL}/admin/login`,
  DASHBOARD: `${EV_BASE_URL}/admin/dashboards/main`,
  CHARGE_POINT: (cpId: string | number) => `${EV_BASE_URL}/admin/resources/charge-points/${cpId}`,
  EVSE: (evseId: string | number) => `${EV_BASE_URL}/admin/resources/e-v-s-es/${evseId}`,
  SESSION: (sessionId: string | number) => `${EV_BASE_URL}/admin/resources/sessions/${sessionId}`,
}

export const BE_PATHS = {
  BOOKING: (bookingId: string) => `/api-proxy/bookings/${bookingId}`,
  BOOKINGS: '/api-proxy/bookings',
  DEVICES: '/api-proxy/devices',
  DEVICES_HEARTBEATS: '/api-proxy/devices/heartbeats',
  DEVICE_HEALTH: (deviceId: string) => `/api-proxy/devices/${deviceId}/health`,
  DEVICE_PING: (ip: string, port: string | number) => `/api-proxy/devices/ping?remoteIp=${ip}&remotePort=${port}`,
  DEVICE_SYNC_STATUS: (deviceId: string | number) => `/api-proxy/devices/${deviceId}/sync-status`,
  DEVICE_FORCE_SYNC: (deviceId: string | number) => `/api-proxy/devices/${deviceId}/sync-config`,
  DEVICE_ACTION: (deviceId: string, action: DeviceAction) => `/api-proxy/devices/${deviceId}/${action}`,
  LOCATION: (locationId: string) => `/api-proxy/locations/${locationId}`,
  LOCATIONS: '/api-proxy/locations',
  LOGIN: '/api-proxy/authentication/login',
  REQUEST_PASSWORD_RESET: '/api-proxy/authentication/request-password-reset',
  RESET_PASSWORD: '/api-proxy/authentication/reset-password',
  ORGANISATIONS: '/api-proxy/organisations',
  PERMITS: '/api-proxy/permits',
  PERMITS_IMPORT: '/api-proxy/permits/import',
  PROVIDERS: '/api-proxy/providers',
  TARIFFS: '/api-proxy/tariffs',
  TOKEN: '/api-proxy/token',
  TARIFFS_REMOTE: '/api-proxy/tariffs/remote',
  TRANSACTIONS: '/api-proxy/transactions',
  TRANSACTIONS_METRICS: (organisationId: string) => `${BE_PATHS.TRANSACTIONS}/metrics/${organisationId}`,
  TRANSACTIONS_CSV: (organisationId: string) => `${BE_PATHS.TRANSACTIONS}/csv/${organisationId}`,
  USERS: '/api-proxy/users',
  PARSE_CSV: '/api/permits/parse-csv',
  LOCATION_CAMERAS: (locationId: string) => `${BE_PATHS.LOCATION(locationId)}/cameras`,
  LOCATION_BARRIERS: (locationId: string) => `${BE_PATHS.LOCATION(locationId)}/barriers`,
  BARRIERS: (organisationId: string) => `${BE_PATHS.ORGANISATIONS}/${organisationId}/barriers`,
  BARRIER_LOGS: `/api-proxy/barrier-logs`,
  BARRIER_RELEASE: (locationId: string, barrierId: string) =>
    `${BE_PATHS.LOCATION_BARRIERS(locationId)}/${barrierId}/release`,
  BARRIER_TOGGLE: (locationId: string, barrierId: string) =>
    `${BE_PATHS.LOCATION_BARRIERS(locationId)}/${barrierId}/toggle`,
  EV_REPORTS: (organisationId: string) => `${BE_PATHS.ORGANISATIONS}/${organisationId}/reports/ev`,
  CHANGE_PASSWORD: () => `/api-proxy/users/me/password`,
}
