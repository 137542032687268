import { makeStyles } from '@mui/styles'

export const styles = makeStyles(() => ({
  title: {
    color: '#000',
    fontWeight: 400,
    fontSize: '2.7rem !important',
    letterSpacing: 0.92,
    textAlign: 'center',
  },
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '60px',
  },
  form: {
    width: '70%',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
}))
