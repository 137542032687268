import Typography from '@mui/material/Typography'
import { Form, Formik } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, InputField, InputFieldProps } from 'src/components'
import { BE_PATHS, FE_PATHS } from 'src/constants'
import { useAxios, useToast } from 'src/hooks'
import * as yup from 'yup'

import { $RainbowContainer } from '../Login/Login.styled'

import { styles } from './ForgotPassword.styles'

const ForgotPasswordInputField = (props: InputFieldProps<Partial<User>>) => InputField<Partial<User>>(props)

const initialValues = { email: '' }

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Email address is required'),
})

export const ForgotPassword = () => {
  const toast = useToast()
  const [success, setSuccess] = React.useState(false)
  const [, requestPasswordReset] = useAxios({
    method: 'POST',
    url: BE_PATHS.REQUEST_PASSWORD_RESET,
  })

  const { container, title, form, link } = styles()

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await requestPasswordReset({ body: values })
      setSuccess(true)
      toast('If an account with the provided email exists, you should receive a password reset link shortly.', {
        variant: 'success',
      })
      resetForm()
    } catch (error) {
      toast(
        'We encountered an issue while processing your request. Please try again later or contact support if the problem persists.',
        { variant: 'error' },
      )
      setSuccess(false)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className={container}>
      <$RainbowContainer />
      <Typography component="h1" variant="h5" className={title}>
        Forgot password?
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className={form}>
            {success && (
              <Typography component="p" variant="body2" color="green" display="flex" justifyContent="center">
                If an account with the provided email exists, you should receive a password reset link shortly.
              </Typography>
            )}
            <ForgotPasswordInputField
              placeholder="Email address"
              label="Email address"
              type="email"
              name="email"
              labelProps={{ color: 'grey82', fontWeight: 300, fontSize: 14 }}
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              text="Send password reset link"
              style={{ padding: '16px' }}
            />
          </Form>
        )}
      </Formik>
      <Typography component="p" variant="body1" color="black" display="flex" justifyContent="center">
        or
        <Link to={FE_PATHS.LOGIN} className={link}>
          log in
        </Link>
        instead
      </Typography>
    </div>
  )
}
