import axios from 'axios'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BE_PATHS, FE_PATHS } from 'src/constants/paths'
import { SentryHelper } from 'src/helpers'

import Token from '../utils/Token'

interface AuthProviderResult {
  isAdmin: boolean | undefined
  isManager: boolean
  isAtLeastManager: boolean
  user: Undefinable<LoggedInUser>
  login: (token: string, onSuccess: (user: LoggedInUser) => void) => void
  logout: () => void
}

const AuthContext = React.createContext<AuthProviderResult>({} as any)

interface AuthProviderProps {
  children: Children
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = React.useState<Undefinable<LoggedInUser>>(() => {
    const token = window.localStorage.getItem('token')

    return token ? Token.parseToken(token)?.user : undefined
  })

  const isAdmin = user?.type === 'ADMIN'
  const isManager = user?.type === 'MANAGER'
  const isAtLeastManager = isAdmin || isManager

  const navigate = useNavigate()
  const location = useLocation()

  const sendToLoginPage = React.useCallback(() => navigate(FE_PATHS.LOGIN), [navigate])

  const login = (token: string, onSuccess?: (user: LoggedInUser) => void) => {
    const parsedToken = Token.parseToken(token)
    const loggedInUser = parsedToken?.user
    setUser(loggedInUser)
    localStorage.setItem('token', token)

    onSuccess?.(loggedInUser)
  }

  const logout = () => {
    setUser(undefined)
    localStorage.clear()
  }

  React.useLayoutEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token')

      if (!token) {
        setUser(undefined)
        return
      }
      try {
        await axios.post(BE_PATHS.TOKEN, { token })
        const decodedToken = Token.parseToken(token)
        const loggedInUser = decodedToken?.user
        setUser(loggedInUser)

        if (location.pathname === '/' || location.pathname === '') {
          navigate(loggedInUser?.type === 'ADMIN' ? FE_PATHS.DASHBOARD : FE_PATHS.LOCATIONS)
        }
      } catch (e) {
        localStorage.clear()
        sendToLoginPage()
      }
    }

    verifyToken()
  }, [location])

  React.useEffect(() => {
    if (!user) return

    SentryHelper.setTags({
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.type,
      organisationId: user.organisationId,
    })
  }, [user])

  const value: AuthProviderResult = React.useMemo(
    () => ({ user, login, logout, isAdmin, isManager, isAtLeastManager }),
    [user, login, logout, isAdmin, isManager, isAtLeastManager],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => React.useContext(AuthContext)
