import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Condition } from 'src/components'
import { useAuthContext } from 'src/context/AuthContext'

import { FE_PATHS } from '../constants'
import {
  BarrierEvents,
  Barriers,
  Bookings,
  Dashboard,
  EvReports,
  ForgotPassword,
  Location,
  Locations,
  Login,
  Metrics,
  Organisations,
  Permits,
  PermitsImport,
  Settings,
  ResetPassword,
  Tariffs,
  Transactions,
  Unauthorized,
  Users,
} from '../screens'

interface ProtectedRouteProps {
  element: React.ReactNode
  hasAccess?: boolean
}

const ProtectedRoute = ({ element, hasAccess = true }: ProtectedRouteProps) => {
  const { user } = useAuthContext()
  const location = useLocation()

  if (!user) {
    return <Navigate to={FE_PATHS.LOGIN} state={{ from: location }} replace />
  }

  return (
    <Condition when={hasAccess} fallback={<Unauthorized />}>
      {element}
    </Condition>
  )
}

const RoutesController = () => {
  const { isAdmin, isAtLeastManager } = useAuthContext()

  return (
    <Routes>
      <Route path="*" element={<Navigate to={FE_PATHS.DASHBOARD} replace />} />
      <Route path={FE_PATHS.LOGIN} element={<Login />} />
      <Route path={FE_PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={FE_PATHS.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={FE_PATHS.DASHBOARD} element={<ProtectedRoute element={<Dashboard />} />} />
      <Route
        path={FE_PATHS.ORGANISATIONS}
        element={<ProtectedRoute element={<Organisations />} hasAccess={isAdmin} />}
      />
      <Route path={FE_PATHS.LOCATIONS} element={<ProtectedRoute element={<Locations />} />} />
      <Route path={`${FE_PATHS.LOCATIONS}/:locationId`} element={<ProtectedRoute element={<Location />} />} />
      <Route path={FE_PATHS.BARRIERS} element={<ProtectedRoute element={<Barriers />} />} />
      <Route
        path={FE_PATHS.BARRIER_EVENTS}
        element={<ProtectedRoute element={<BarrierEvents />} hasAccess={isAtLeastManager} />}
      />
      <Route path={FE_PATHS.PERMITS} element={<ProtectedRoute element={<Permits />} />} />
      <Route
        path={FE_PATHS.PERMITS_IMPORT}
        element={<ProtectedRoute element={<PermitsImport />} hasAccess={isAdmin} />}
      />
      <Route path={FE_PATHS.BOOKINGS} element={<ProtectedRoute element={<Bookings />} />} />
      <Route path={FE_PATHS.TRANSACTIONS} element={<ProtectedRoute element={<Transactions />} />} />
      <Route path={FE_PATHS.METRICS} element={<ProtectedRoute element={<Metrics />} hasAccess={isAtLeastManager} />} />

      <Route
        path={FE_PATHS.EV_REPORTS}
        element={<ProtectedRoute element={<EvReports />} hasAccess={isAtLeastManager} />}
      />
      <Route path={FE_PATHS.SETTINGS} element={<ProtectedRoute element={<Settings />} />} />

      <Route path={FE_PATHS.TARIFFS} element={<ProtectedRoute element={<Tariffs />} />} />
      <Route path={FE_PATHS.USERS} element={<ProtectedRoute element={<Users />} hasAccess={isAdmin} />} />
    </Routes>
  )
}

export default RoutesController
