import * as yup from 'yup'

export const DeviceSchema = yup.object().shape({
  logo: yup.string().nullable(),
  name: yup.string().required('* Required'),
  locationId: yup.string().required('* Required'),
  type: yup
    .string()
    .oneOf(['access_control', 'pay_on_entry', 'pay_on_exit', 'whitelist'], '* Required')
    .required('* Required'),
  parkingTariffId: yup.string().nullable(),
  remoteIp: yup.string().required('* Required'),
  remotePort: yup.string().required('* Required'),
  alioIp: yup.string().nullable(),
  alioPort: yup.string().nullable(),
  printerIp: yup.string().nullable(),
  printerPrints: yup.number().nullable(),
  supportsPrinting: yup.boolean().nullable(),
  relaySwitchId: yup
    .string()
    .nullable()
    .when('type', { is: 'access_control', then: yup.string().required('* Required') }),
  whitelistMinutes: yup
    .number()
    .nullable()
    .when('type', { is: 'whitelist', then: yup.number().required('* Required') }),
})
