import DevicesIcon from '@mui/icons-material/DevicesOther'
import SyncIcon from '@mui/icons-material/Sync'
import { x } from '@xstyled/styled-components'
import React from 'react'
import { Badge, Button, Condition, EmptyState, Table } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { useAuthContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'
import { DeviceSyncStatus } from 'src/screens/Devices/components'
import { DeleteDeviceModal } from 'src/screens/Devices/Modals'
import { DeviceType, IDevice, IDeviceSyncStatus } from 'src/typings/device'
import { isPayStation } from 'src/utils'

import { DeviceModal } from './DeviceModal'

interface LocationDevicesProps {
  devices?: IDevice[]
  locationId?: string
  tariffs?: ITariff[]
  onDataRefetch: () => void
}

function mapDeviceTypeToLabel(type: DeviceType) {
  switch (type) {
    case 'pay_on_entry':
      return 'Paystation (Entry)'
    case 'pay_on_exit':
      return 'Paystation (Exit)'
    case 'access_control':
      return 'Access Control'
    case 'whitelist':
      return 'Whitelist'
    default:
      return 'Unknown'
  }
}

export const LocationDevices = ({ devices, locationId, tariffs, onDataRefetch }: LocationDevicesProps) => {
  const { isAdmin } = useAuthContext()

  const [deleteDeviceModalOpen, setDeleteDeviceModalOpen] = React.useState(false)
  const [deviceModalOpen, setDeviceModalOpen] = React.useState<boolean>(false)

  const [, forceDeviceSync] = useAxios<{ isSynced: boolean }>({ url: '', method: 'POST', runOnInit: false })
  const [, refetchSyncStatus] = useAxios<IDeviceSyncStatus>({ url: '', method: 'GET', runOnInit: false })

  const [deviceToDelete, setDeviceToDelete] = React.useState<IDevice>()
  const [deviceToEdit, setDeviceToEdit] = React.useState<IDevice>()
  const [syncingDevices, setSyncingDevices] = React.useState<{ [key: string]: boolean }>({})

  const toast = useToast()

  const handleDelete = async (device: IDevice) => {
    setDeviceToDelete(device)
    setDeleteDeviceModalOpen(true)
  }

  return (
    <div>
      <Condition when={isAdmin}>
        <x.div mt="10px" mb="20px" w="100%" display="flex">
          <Button text="+ Add device" variant="accent" onClick={() => setDeviceModalOpen(true)} />
        </x.div>
      </Condition>
      {devices && devices?.length === 0 && (
        <EmptyState
          title="There are no devices currently set up"
          subtitle="Devices are a great way for you to configure and monitor the status and remote functionality of equipment deployed in various locations."
          icon={<DevicesIcon />}
          buttonProps={
            isAdmin
              ? {
                  text: 'Create a device',
                  variant: 'accent',
                  onClick: () => setDeviceModalOpen(true),
                  style: { h: '50px' },
                }
              : undefined
          }
        />
      )}
      {devices && devices.length > 0 && (
        <Table
          items={devices}
          headers={[
            { key: 'name' },
            { key: 'type', format: (type: DeviceType) => mapDeviceTypeToLabel(type) },
            { key: 'remoteIp' },
            { key: 'remotePort' },
            {
              customKey: 'Configuration',
              align: 'center',
              render: (_value, item) => (
                <DeviceSyncStatus
                  deviceId={item?.id}
                  type="config"
                  onAutomaticRefetchStateChange={(isSyncingDevice) => {
                    if (isSyncingDevice) {
                      setSyncingDevices((prev) => ({ ...prev, [item.id]: true }))
                    } else {
                      setSyncingDevices((prev) => ({ ...prev, [item.id]: false }))
                    }
                  }}
                />
              ),
            },
            {
              customKey: 'Tariff',
              align: 'center',
              render: (_value, item) =>
                isPayStation(item.type) ? (
                  <DeviceSyncStatus
                    deviceId={item?.id}
                    type="tariff"
                    onAutomaticRefetchStateChange={(isSyncingDevice) => {
                      if (isSyncingDevice) {
                        setSyncingDevices((prev) => ({ ...prev, [item.id]: true }))
                      } else {
                        setSyncingDevices((prev) => ({ ...prev, [item.id]: false }))
                      }
                    }}
                  />
                ) : (
                  <Badge variant="grey">N/A</Badge>
                ),
            },
          ]}
          actions={
            isAdmin && [
              {
                label: 'Sync device',
                disabled: (item) => syncingDevices[item.id],
                onClick: async (item) => {
                  setSyncingDevices((prev) => ({ ...prev, [item.id]: true }))

                  try {
                    const response = await forceDeviceSync({ url: BE_PATHS.DEVICE_FORCE_SYNC(item.id), timeout: 60000 })

                    if (!response?.isSynced) throw new Error('Failed to sync device')

                    toast(`Successfully synced device [${item.name}]`, { variant: 'success' })
                  } catch (error) {
                    toast(`Failed to sync device [${item.name}]`, { variant: 'error' })
                  } finally {
                    await refetchSyncStatus({ url: BE_PATHS.DEVICE_SYNC_STATUS(item.id) })
                  }

                  setSyncingDevices((prev) => ({ ...prev, [item.id]: false }))
                },
                icon: <SyncIcon />,
              },
              {
                label: 'Edit',
                onClick: (item: IDevice) => {
                  setDeviceToEdit(item)
                  setDeviceModalOpen(true)
                },
                type: 'edit',
              },
              { label: 'Delete', onClick: handleDelete, type: 'delete' },
            ]
          }
        />
      )}
      <Condition when={isAdmin}>
        <DeviceModal
          isOpen={deviceModalOpen}
          device={deviceToEdit}
          tariffs={tariffs}
          locationId={locationId}
          onSubmitSuccess={async () => {
            await onDataRefetch()
          }}
          onClose={() => {
            setDeviceModalOpen(false)
            setDeviceToEdit(undefined)
          }}
        />
      </Condition>
      <Condition when={isAdmin}>
        <DeleteDeviceModal
          device={deviceToDelete as IDevice}
          isOpen={deleteDeviceModalOpen}
          onClose={() => setDeleteDeviceModalOpen(false)}
          onDelete={async () => {
            await onDataRefetch()
          }}
        />
      </Condition>
    </div>
  )
}
