import { makeStyles } from '@mui/styles'

export const styles = makeStyles(() => ({
  title: {
    color: '#000',
    fontWeight: 400,
    fontSize: '2.7rem !important',
    letterSpacing: 0.92,
    textAlign: 'center',
    marginBottom: '40px',
  },
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
  },
  form: {
    width: '70%',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  link: {
    marginLeft: '5px',
    marginRight: '5px',
    color: 'black',
    textDecorationThickness: '2px',
    textDecorationLine: 'underline',
  },
}))
